import dayjs from 'dayjs';
var isoWeek = require('dayjs/plugin/isoWeek');
var advancedFormat = require('dayjs/plugin/advancedFormat')

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

const getFirstMondayOf = (dayInThisMonth) => {
  const firstDayOfTheMonth = dayInThisMonth.startOf('month');
  const dayOfWeek = firstDayOfTheMonth.isoWeekday();

  if (dayOfWeek !== 1) {
    return firstDayOfTheMonth.add(8 - dayOfWeek, 'days');
  }
  return firstDayOfTheMonth;
};

class nextStartDate {
  constructor() {
    const today = dayjs(); // today
    let firstMonday = getFirstMondayOf(dayjs()); // first monday of this month

    if (today.diff(firstMonday) > 24 * 60 * 60 * 1000) {
      // if the first monday of this month has passed, go to next month, reset to beginning of the month
      firstMonday = getFirstMondayOf(today.add(1, 'month'));
    }

    // set next start date
    this.nextStartDate = firstMonday;
  }

  forwardMonths(nMonths) {
    // add n months to next start date, reset to beginning of the month
    const futureMonth = this.nextStartDate.add(nMonths, 'month');
    // get first monday of future month, set as start date
    this.nextStartDate = getFirstMondayOf(futureMonth);
    return this;
  }

  value() {
    return this.nextStartDate;
  }

  standard() {
    return this.nextStartDate.format('MMMM Do, YYYY');
  }

  pretty() {
    return this.nextStartDate.format('dddd, MMMM Do YYYY');
  }

  monthYear() {
    return this.nextStartDate.format('MMMM YYYY');
  }

  shortMonthYear() {
    return this.nextStartDate.format('MMM YYYY');
  }

  late(nMonths) {
    const futureMonth = this.nextStartDate.subtract(nMonths, 'month');
    this.nextStartDate = getFirstMondayOf(futureMonth);
    return this;
  }

  immediately() {
    const today = dayjs(); // today
    return today.format('dddd, MMMM Do YYYY');
  }

  fromNow() {
    return dayjs().diff(this.nextStartDate, 'days');
  }

  cohortNumber() {
    let cohort = Math.abs(this.value().diff('2020-12', 'month'));
    
    return cohort + 48;
  }
}

export default nextStartDate;
