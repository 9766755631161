import { safeCredentials } from './fetchHelpers';
import { sha256 } from 'js-sha256';

const conversionTracker = (window, event, options = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(event, options);
  }

  switch (event) {
  case 'ViewContent':
    // Google Optimize Activation
    // if (window.dataLayer) {
    //   window.setTimeout(function () {
    //     window.dataLayer.push({'event': 'optimize.activate'});
    //   }, 0);
    // }
    break;
  case 'AddPaymentInfo':
    fetch('/api/v2/leads', safeCredentials({
      method: 'POST',
      body: JSON.stringify({
        event: 'AddPaymentInfo',
        nanomajor_long_symbol: options.nanomajor_long_symbol,
        variant: options.variant,
        first_name: options.first_name,
        last_name: options.last_name,
        email: options.email,
        start_date: options.start_date,
        discount_code: options.discount_code,
        terms_of_service_agreement_checked: options.terms_of_service_agreement_checked,
        privacy_policy_agreement_checked: options.privacy_policy_agreement_checked,
      }),
    }));
    break;
  case 'InitiatePayment':
    fetch('/api/v2/leads', safeCredentials({
      method: 'POST',
      body: JSON.stringify({
        event: 'InitiatePayment',
        nanomajor_long_symbol: options.nanomajor_long_symbol,
        variant: options.variant,
        first_name: options.first_name,
        last_name: options.last_name,
        email: options.email,
        start_date: options.start_date,
        payment_source: options.payment_source,
        discount_code: options.discount_code,
        terms_of_service_agreement_checked: options.terms_of_service_agreement_checked,
        privacy_policy_agreement_checked: options.privacy_policy_agreement_checked,
      }),
    }));
    break;
  default:
    fetch('/api/v2/activities', safeCredentials({ method: 'POST' }));
  }

  // GOOGLE
  // https://www.simoahava.com/analytics/facebook-conversions-api-gtm-server-side-tagging/
  if (window.dataLayer) {
    
    switch (event) {
    case 'ViewProduct':
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: [{
            currency: 'USD',
            item_id: options.nanomajor_long_symbol,
            item_name: options.nanomajor_long_symbol,
            item_category: options.content_category,
            quantity: 1,
          }],
        },
      });
      break;
    case 'InitiateCheckout':
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          checkout: {
            actionField: { step: options.step },
          },
        },
      });
      break;
    case 'PickProduct':
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      // Track when a NM is chosen (ex: immersive vs. personalized)
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [{
            currency: 'USD',
            item_id: options.nanomajor_long_symbol,
            item_name: options.nanomajor_long_symbol,
            item_category: options.content_category,
            quantity: 1,
          }],
        },
      });
      break;
    case 'AddToCart':
    //   window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    //   // Track when NM path is chosen (ex: immersive vs. personalized)
    //   window.dataLayer.push({
    //     event: 'add_to_cart',
    //     ecommerce: {
    //       items: [{
    //         currency: 'USD',
    //         item_id: options.nanomajor_long_symbol,
    //         item_name: options.nanomajor_long_symbol,
    //         item_category: options.content_category,
    //         item_variant: options.variant,
    //         price: options.value,
    //         quantity: 1,
    //       }],
    //     },
    //   });
      break;
    case 'AddPaymentInfo':
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_to_cart',
        user_data: {
          em: sha256(options.email),
          fn: sha256(options.first_name),
          ln: sha256(options.last_name),
          email_address: sha256(options.email),
          address: {
            first_name: sha256(options.first_name),
            last_name: sha256(options.last_name),
          },
        },
        ecommerce: {
          payment_type: 'Credit Card',
          currency: 'USD',
          value: options.value,
          items: [
            {
              item_id: options.nanomajor_long_symbol,
              item_name: options.nanomajor_long_symbol,
              item_category: options.content_category,
              item_variant: options.variant,
              price: options.value,
              quantity: 1,
            },
          ],
        },
      });
      break;
    case 'InitiatePayment':
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_payment_info',
        user_data: {
          em: sha256(options.email),
          fn: sha256(options.first_name),
          ln: sha256(options.last_name),
          email_address: sha256(options.email),
          address: {
            first_name: sha256(options.first_name),
            last_name: sha256(options.last_name),
          }
        },
        ecommerce: {
          payment_type: "Credit Card",
          currency: 'USD',
          value: options.value,
          items: [{
            item_id: options.nanomajor_long_symbol,
            item_name: options.nanomajor_long_symbol,
            item_category: options.content_category,
            item_variant: options.variant,
            price: options.value,
            quantity: 1,
          }],
        },
      });
      break;
    case 'Purchase':
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'purchase',
        user_data: {
          em: sha256(options.email),
          fn: sha256(options.first_name),
          ln: sha256(options.last_name),
          email_address: sha256(options.email),
          address: {
            first_name: sha256(options.first_name),
            last_name: sha256(options.last_name),
          }
        },
        ecommerce: {
          transaction_id: options.id,
          currency: 'USD',
          value: options.value,
          items: [{
            item_id: options.nanomajor_long_symbol,
            item_name: options.nanomajor_long_symbol,
            item_category: options.content_category,
            item_variant: options.variant,
            price: options.value,
            quantity: 1,
          }],
        },
      });
      break;
    case 'Lead':
      window.dataLayer.push({
        event: 'generate_lead',
        eventCategory: 'generate_lead',
        eventAction: options.content_category,
        eventLabel: options.content_name,
      });
      break;
    case 'ViewContent':
      window.dataLayer.push({
        event: 'view_item',
        eventCategory: 'view_item',
        eventAction: options.content_category,
        eventLabel: options.content_name,
      });
      break;
    default:
    }
  }

  // FACEBOOK
  /*
  if (window.fbq && process.env.NODE_ENV === 'production') {
    switch (event) {
    case 'ViewContent':
      window.fbq('track', 'ViewContent', options);
      break;
    case 'ViewProduct':
      // Track when a NM is viewed
      window.fbq('track', 'ViewContent', {
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
      });
      break;
    case 'Lead':
      window.fbq('track', 'Lead', {
        content_category: options.content_category,
        content_name: options.content_name,
      });
      break;
    case 'CompleteRegistration':
      window.fbq('track', 'CompleteRegistration', options);
      break;
    case 'InitiateCheckout':
      window.fbq('track', 'InitiateCheckout');
      break;
    case 'PickProduct':
      // Track when a NM is chosen
      window.fbq('track', 'PickProduct', {
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
      });
      break;
    case 'AddToCart':
      // Track when NM path is chosen (ex: immersive vs. personalized)
      window.fbq('track', 'AddToCart', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
        product_variant: options.variant,
      });
      break;
    case 'AddPaymentInfo':
      // Track when payment information is added in the checkout flow (ex: click, landing page on billing info)
      window.fbq('track', 'AddPaymentInfo', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
        product_variant: options.variant,
      });
      break;
    case 'InitiatePayment':
      // Track when payment button is clicked
      window.fbq('track', 'InitiatePayment', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
        product_variant: options.variant,
      });
      break;
    case 'Purchase':
      window.fbq('track', 'Purchase', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_ids: [options.nanomajor_long_symbol],
        content_type: 'product',
        product_variant: options.variant,
      });
      break;
    default:
    }
  }
  */

  // FULFILLKIT
  if (window.ffk && process.env.NODE_ENV === 'production') {
    switch (event) {
    case 'ViewContent':
      window.ffk('track', 'ViewContent', options);
      break;
    case 'ViewProduct':
      // Track when a NM is viewed
      window.ffk('track', 'ViewContent', {
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
      });
      break;
    case 'Lead':
      window.ffk('track', 'Lead', {
        content_category: options.content_category,
        content_name: options.content_name,
        email: options.email,
      });
      if (options.email) {
        window.ffk('people', 'set', {email: options.email});
      }
      break;
    case 'CompleteRegistration':
      window.ffk('track', 'CompleteRegistration', options);
      break;
    case 'InitiateCheckout':
      window.ffk('track', 'InitiateCheckout', options);
      break;
    case 'PickProduct':
      // Track when a NM is chosen
      window.ffk('track', 'PickProduct', {
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
      });
      break;
    case 'AddToCart':
      // Track when NM path is chosen (ex: immersive vs. personalized)
      window.ffk('track', 'AddToCart', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
        product_variant: options.variant,
      });
      break;
    case 'AddPaymentInfo':
      // Track when payment information is added in the checkout flow (ex: click, landing page on billing info)
      window.ffk('track', 'AddPaymentInfo', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
        product_variant: options.variant,
        email: options.email,
        first_name: options.first_name,
        last_name: options.last_name,
        start_date: options.start_date,
        discount_code: options.discount_code,
        terms_of_service_agreement_checked: options.terms_of_service_agreement_checked,
        privacy_policy_agreement_checked: options.privacy_policy_agreement_checked,
      });
      if (options.email) {
        window.ffk('people', 'set', {email: options.email});
      }
      break;
    case 'InitiatePayment':
      // Track when payment button is clicked
      window.ffk('track', 'InitiatePayment', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
        product_variant: options.variant,
        email: options.email,
        first_name: options.first_name,
        last_name: options.last_name,
        start_date: options.start_date,
        discount_code: options.discount_code,
        payment_source: options.payment_source,
        terms_of_service_agreement_checked: options.terms_of_service_agreement_checked,
        privacy_policy_agreement_checked: options.privacy_policy_agreement_checked,
      });
      if (options.email) {
        window.ffk('people', 'set', {email: options.email});
      }
      break;
    case 'Purchase':
      window.ffk('track', 'Purchase', {
        value: options.value,
        currency: 'USD',
        content_category: options.content_category,
        content_name: options.nanomajor_long_symbol,
        content_type: 'product',
        product_variant: options.variant,
        email: options.email,
        first_name: options.first_name,
        last_name: options.last_name,
        start_date: options.start_date,
      });
      if (options.email) {
        window.ffk('people', 'set', {email: options.email});
      }
      break;
    default:
    }
  }
};

export default conversionTracker;
